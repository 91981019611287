import type React from "react";
import {
	Children,
	type ReactElement,
	type ReactNode,
	useEffect,
	useRef,
} from "react";
import type { SxStyleProp } from "theme-ui";

import type { ComponentProps } from "@Components";
import type { ThemeDefinition } from "@Themes";
import type { SpacerSizes } from "@Tokens";
import { getFlexBasis } from "@Utils";
import { ScrollButtons } from "./ScrollButtons";
import { usePosition } from "./usePosition";

type ResponsiveSpace = [
	keyof SpacerSizes | 0,
	keyof SpacerSizes | 0,
	keyof SpacerSizes | 0,
];

export interface CarouselRenderPropProps extends ComponentProps {
	itemGap?: ResponsiveSpace;
	containerPadding?: ResponsiveSpace;
	maxItemsOnShow?: [number, number, number];
	oneColumnOnMobile?: boolean;
	/**
	 * For 1 child, how many items should space be given for (mobile, tablet, desktop)?
	 * For 2 children, how many items should space be given for (mobile, tablet, desktop)?
	 * ...
	 * For n children, how many items should space be given for (mobile, tablet, desktop)?
	 *
	 * Leave undefined to default it.
	 */
	itemsOnShowOverride?: [number?, number?, number?][];
	disabled?: boolean;
	buttonContainerStyles?: SxStyleProp;
	disabledLoopScroll?: boolean;
	lastItemScrollOffset?: number;
	resetScroll?: boolean;
	render: (props: {
		carousel: ReactNode;
		scrollButtons: ReactNode;
	}) => ReactElement;
	/**
	 * Fit within the parent container rather than aiming to fit in the page margins.
	 */
	containInParent?: boolean;
}

const themeValueResolver =
	(
		styles: CarouselRenderPropProps["containerPadding"],
		transform: (themeValue: any) => any = (themeValue) => themeValue,
	) =>
	(theme: ThemeDefinition) =>
		styles!.map((value) =>
			transform(typeof value === "string" ? theme.space[value!] : value),
		);

export const CarouselRenderProp: React.FC<
	React.PropsWithChildren<CarouselRenderPropProps>
> = ({
	className,
	children,
	itemGap = ["xs", "l", "l"],
	containerPadding = ["xs", "2xl", 0],
	maxItemsOnShow = [1.1, 3, 4],
	itemsOnShowOverride = [[1, 3, 4]],
	disabled = false,
	buttonContainerStyles = {},
	oneColumnOnMobile,
	lastItemScrollOffset,
	disabledLoopScroll,
	resetScroll,
	render,
	containInParent = false,
}) => {
	const containerRef = useRef<HTMLDivElement>(null);

	const { scrollLeft, scrollRight, disabledScrollLeft, disabledScrollRight } =
		usePosition({
			ref: containerRef,
			children,
			lastItemScrollOffset,
			disabledLoopScroll,
		});
	const childrenCount = Children.count(children);

	const itemsOnShow: [number, number, number] = [
		itemsOnShowOverride[childrenCount - 1]?.[0] ||
			Math.min(maxItemsOnShow[0], childrenCount),
		itemsOnShowOverride[childrenCount - 1]?.[1] ||
			Math.min(maxItemsOnShow[1], childrenCount),
		itemsOnShowOverride[childrenCount - 1]?.[2] ||
			Math.min(maxItemsOnShow[2], childrenCount),
	];

	const shouldShowScrollButtons = !disabled && childrenCount > itemsOnShow[2];

	useEffect(() => {
		if (resetScroll) {
			// There is a bug only in Chrome where the scroll is not always 0 when the page loads.
			// This is a workaround.
			containerRef.current?.scroll?.({ left: 0 });
		}
	}, []);

	return render({
		carousel: (
			<div
				data-id="carousel-container"
				ref={containerRef}
				className={[className, "hide-scrollbars"].filter(Boolean).join(" ")}
				sx={{
					position: "relative",
					display: "grid",
					overflowX: "auto",
					overflowY: "hidden",
					gridAutoFlow: oneColumnOnMobile ? ["row", "column"] : "column",
					gridAutoColumns: (t) =>
						itemsOnShow.map((numItems, index) =>
							getFlexBasis(numItems, itemGap[index])(t),
						),
					scrollSnapType: "x mandatory",
					gap: itemGap,
					...(!containInParent && {
						paddingX: containerPadding,
						scrollPadding: themeValueResolver(containerPadding),
						marginX: themeValueResolver(containerPadding, (v) => `-${v}px`),
					}),
					"> *": {
						scrollSnapAlign: "start",
						// undo flex styles
						flexBasis: "auto",
						maxWidth: "none",
						marginLeft: 0,
						marginRight: 0,
					},
				}}
			>
				{children}
			</div>
		),
		scrollButtons: shouldShowScrollButtons && (
			<ScrollButtons
				scrollLeft={scrollLeft}
				scrollRight={scrollRight}
				disabledScrollLeft={disabledScrollLeft}
				disabledScrollRight={disabledScrollRight}
				sx={buttonContainerStyles}
			/>
		),
	});
};
