import type React from "react";

import { negativeSpace } from "@Utils";
import {
	CarouselRenderProp,
	type CarouselRenderPropProps,
} from "./CarouselRenderProp";

type CarouselProps = Omit<CarouselRenderPropProps, "render">;

export const Carousel: React.FC<React.PropsWithChildren<CarouselProps>> = ({
	buttonContainerStyles,
	...props
}) => (
	<CarouselRenderProp
		render={({ carousel, scrollButtons }) => (
			<div
				sx={{
					position: "relative",
				}}
			>
				{carousel}
				{scrollButtons}
			</div>
		)}
		buttonContainerStyles={{
			position: "absolute",
			top: negativeSpace("3xl"),
			right: "0px",
			...buttonContainerStyles,
		}}
		{...props}
	/>
);
